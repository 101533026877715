<template>
  <div class="addenterprise">
    <div class="addenterprise_top" v-if="id">
      <div class="img_coverImage">
        <img :src="form.coverImage" alt="" />
      </div>
      <div class="addenterprise_top_right">
        <div>
          <span>{{ form.partnerName }}</span>
          <span class="directLeague" v-if="form.directLeague == 2">直营</span>
          <span class="directLeague" v-if="form.directLeague == 1">加盟</span>
        </div>
        <div style="padding: 10px 0px">
          <span>{{ form.partnerWebsite }}</span>
        </div>
        <div>
          <span>{{ form.concatPhone }}</span>
          <span>{{ form.province }}-{{ form.city }}-{{ form.district }}</span>
        </div>
      </div>
    </div>
    <div class="essential_information">
      <div class="store_table">
        <div
          class="store_table_item"
          @click="tabitem(item)"
          v-for="(item, index) in tabList"
          :key="index"
        >
          <span :class="tabindex == item.id ? 'active' : ''">{{ item.val }} </span>
        </div>
      </div>
      <div v-if="tabindex == 1" style="background: #ffffff">
        <div class="form_essential">
          <el-form ref="form" :model="form" :rules="rules" label-width="130px">
            <div>
              <el-form-item label="封面图:" prop="coverImage">
                <img-big-upload
                  :imageShow.sync="form.coverImage"
                  :addTitleShow="false"
                  @removeImg="removeImgDians"
                  @uploadOneImgShow="uploadOneImgShow"
                />
              </el-form-item>
              <span
                style="font-size: 14px; color: #999999; position: relative; left: 100px; top: -10px"
                >建议图片整体尺寸为200*200px</span
              >
              <el-form-item label="封面图:" prop="loginCover">
                <img-big-upload
                  :imageShow.sync="form.loginCover"
                  :addTitleShow="false"
                  @removeImg="removeLoginCover"
                  @uploadOneImgShow="uploadOneLoginCoverShow"
                />
              </el-form-item>
              <span
                style="font-size: 14px; color: #999999; position: relative; left: 100px; top: -10px"
                >建议图片整体尺寸为1920*1080px</span
              >
              <el-form-item label="合作伙伴类型:" prop="partnerType">
                <el-radio-group v-model="form.partnerType">
                  <el-radio
                    v-for="(item, index) in InformationTypes"
                    :key="index"
                    :label="item.codeName"
                    >{{ item.codeName }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="合作类型:" prop="cooperationType">
                <el-radio-group v-model="form.cooperationType">
                  <el-radio
                    v-for="(item, index) in cooperationTypes"
                    :key="index"
                    :label="item.codeName"
                    >{{ item.codeName }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="合作伙伴类别:" prop="directLeague">
                <el-radio-group v-model="form.directLeague">
                  <el-radio
                    v-for="(item, index) in partnerCategory"
                    :key="index"
                    :label="index + 1"
                    >{{ item.codeName }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="合作伙伴名称:" prop="partnerName">
                <el-input v-model="form.partnerName" placeholder="请输入合作名称" />
              </el-form-item>

              <el-form-item label="合作伙伴官网:" prop="partnerWebsite">
                <el-input v-model="form.partnerWebsite" placeholder="请输入合作官网" />
              </el-form-item>

              <el-form-item label="所在地区:" prop="district">
                <div class="select_inner">
                  <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
                    <el-option
                      v-for="item in operatingList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <div style="display: inline-block; width: 10px"></div>
                  <el-select v-model="form.city" placeholder="请选择市" @change="districtchange">
                    <el-option
                      v-for="item in operatingLists"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <div style="display: inline-block; width: 10px"></div>
                  <el-select v-model="form.district" placeholder="请选择区">
                    <el-option
                      v-for="item in districtList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>

              <el-form-item label="关联智参中心" prop="">
                <el-autocomplete
                  v-model="form.zcName"
                  :fetch-suggestions="
                    (res, cb) => {
                      return querySearchAsyncapts2(res, cb)
                    }
                  "
                  placeholder="请搜索智参中心"
                  @select="
                    (res) => {
                      return handleSelectcapts2(res)
                    }
                  "
                >
                  <template slot-scope="{ item }">
                    <div class="name">
                      <img
                        :src="item.coverImage"
                        style="border-radius: 28px"
                        width="28px"
                        height="28px"
                        alt=""
                      />
                      <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                        item.zcName
                      }}</span>
                    </div>
                  </template>
                </el-autocomplete>
              </el-form-item>
              <el-form-item label="合作伙伴对接人:" prop="partnerContactPerson">
                <el-input v-model="form.partnerContactPerson" placeholder="请输入合作对接人" />
              </el-form-item>

              <el-form-item label="手机号码:" prop="concatPhone">
                <el-input v-model="form.concatPhone" placeholder="请输入联系电话" />
              </el-form-item>

              <el-form-item label="合作伙伴简介:" prop="partnerIntro">
                <el-input
                  v-model="form.partnerIntro"
                  type="textarea"
                  placeholder="请输入合作简介"
                />
              </el-form-item>
              <el-form-item label="后台链接:" prop="url">
                <el-input v-model="form.url" placeholder="请输入后台链接" />
              </el-form-item>
              <el-form-item label="手册链接:">
                <el-input v-model="form.manualLink" placeholder="请输入手册链接" />
                <div class="tips_row">
                  说明:合作伙伴后台说明操作手册的线上链接，如没有在合作伙伴后台则不展示
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="margin-top: 30px" class="dialog-footer">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="clcans">取消</el-button>
        </div>
      </div>
      <div v-if="tabindex == 2 && form.partnerType == '城市合伙人'">
        <div class="select_inner">
          <el-form ref="forminfo" :model="queryInfo" label-width="80px">
            <div class="form">
              <div class="comble_txt">
                <el-form-item label="所在地区:" prop="">
                  <el-select
                    v-model="queryInfo.province"
                    placeholder="请选择省份"
                    @change="citychanges"
                  >
                    <el-option :value="''" label="全部"></el-option>
                    <el-option
                      v-for="item in operatingList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <div style="display: inline-block; width: 10px"></div>
                  <el-select
                    v-model="queryInfo.city"
                    placeholder="请选择市"
                    @change="districtchanges"
                  >
                    <el-option :value="''" label="全部"></el-option>
                    <el-option
                      v-for="item in operatingLists"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <div style="display: inline-block; width: 10px"></div>
                  <el-select v-model="queryInfo.district" placeholder="请选择区" @change="search">
                    <el-option :value="''" label="全部"></el-option>
                    <el-option
                      v-for="item in districtList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="合作类型:" prop="">
                  <el-radio-group v-model="queryInfo.channelType" @change="search">
                    <el-radio-button label="" :value="''">全部</el-radio-button>
                    <el-radio-button
                      :label="item.codeName"
                      v-for="(item, index) in InformationTypes"
                      :key="index"
                      >{{ item.codeName }}</el-radio-button
                    >
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="业务模式:" prop="">
                  <el-radio-group v-model="queryInfo.directLeague" @change="search">
                    <el-radio-button label="" :value="''">全部</el-radio-button>
                    <el-radio-button label="1">直营模式</el-radio-button>
                    <el-radio-button label="2">加盟模式</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label=" "
                  label-width="0px"
                  style="padding-top: 20px; margin-bottom: 0"
                >
                  <div class="input-with">
                    <div style="display: flex">
                      <el-button type="primary" @click="clickpartner">批量关联</el-button>
                    </div>
                    <div>
                      <el-input
                        placeholder="请输入合作伙伴名称"
                        v-model="queryInfo.queryConditions"
                        class="input-with-select"
                      >
                        <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div
          class="tab_txt"
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
        >
          <span style="position: relative; top: -10px">当前条件下共查出{{ total }}家合作伙伴</span>
          <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
            <div class="storeCenter_item_top">
              <div class="storeCenter_item_top_type">
                <span>{{ item.partnerType }}</span>
              </div>
              <div class="storeCenter_item_top_left">
                <img :src="item.coverImage" alt="" />
                <div class="storeCenter_item_top_left_flex">
                  <div style="display: flex; align-items: center">
                    <h5 style="display: inline-block; margin: 0; font-size: 18px">
                      {{ item.partnerName }}
                    </h5>
                  </div>
                  <div style="padding-top: 10px; text-decoration-line: underline; color: #4e93fb">
                    <span>{{ item.partnerWebsite }}</span>
                  </div>
                  <p class="address">
                    <span style="padding-left: 0px"> {{ item.partnerContactPerson }}</span>
                    <span> {{ item.concatPhone }}</span>
                    <span> {{ item.province }}-{{ item.city }}</span>
                  </p>
                </div>
              </div>
              <div class="storeCenter_item_top_right">
                <div class="operation_area">
                  <el-button type="info" plain>关联企业</el-button>
                  <el-button type="info" plain>关联订单</el-button>
                  <span
                    @click="secure(item)"
                    style="margin-left: 10px; color: #fd5469; cursor: pointer"
                    >解除关联</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="new_page"
      v-if="listData.length && tabindex == 2 && form.partnerType == '城市合伙人'"
    >
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog title="批量关联" :visible.sync="dialogVisibles" width="50%" class="account_dialog">
      <div class="dialog_img">
        <div>
          <img :src="form.coverImage" alt="" />
        </div>
        <div class="partnerName">
          <span>{{ form.partnerName }}</span>
        </div>
      </div>
      <el-form label-width="100px" :model="paraminfo" ref="infoform">
        <el-form-item label="所在地区:" prop="">
          <el-select v-model="paraminfo.province" placeholder="请选择省份" @change="citychang">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="paraminfo.city" placeholder="请选择市" @change="districtchang">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="paraminfo.district" placeholder="请选择区" @change="getAllPartner">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in districtList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合作伙伴类型:" prop="">
          <el-radio-group v-model="paraminfo.channelType" @change="getAllPartner">
            <el-radio-button label="" :value="''">全部</el-radio-button>
            <el-radio-button
              :label="item.codeName"
              v-for="(item, index) in InformationTypes"
              :key="index"
              >{{ item.codeName }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTables"
        v-loading="loadingisen"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        :data="listgisen"
        stripe
        width="100%"
        class="in_table"
        @selection-change="handleSelectionChange"
        row-key="id"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column type="selection" align="center" :reserve-selection="true" />
        <el-table-column label="封面图" prop="coverImage" align="center">
          <template v-slot="{ row }">
            <img width="20" height="20" :src="row.coverImage" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="合作伙伴名称" prop="partnerName" align="center"></el-table-column>

        <el-table-column label="所在区域" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.province }}-{{ row.city }}-{{ row.district }}</span>
          </template>
        </el-table-column>

        <el-table-column label="合作伙伴类型" prop="partnerType" align="center"></el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="paraminfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="paraminfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaldins"
          @size-change="handleSizeChanges"
          @current-change="handleCurrentChanges"
        />
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="associateCompany">确 定</el-button>
        <el-button @click="dialogVisibles = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  save,
  update,
  selectPartnerById,
  cityPartnerPage,
  associateCompany,
  getWisdomGinsengList,
  getAllPartner,
  unbindPartner
} from '@/api/channel'

import { getCode, getCityAll } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  city: '',
  district: '',
  id: '', //主键
  idList: [],
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  province: '',

  queryConditions: '' //模糊查询字段
})
export default {
  name: 'addpartner',
  computed: {
    id() {
      return this.$route.query.id
    },
    pageNum() {
      return this.$route.query.pageNum
    }
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo }, //查询参数
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      form: {
        cityPartnerIds: [], //城市合伙人类型的合作伙伴关联的伙伴表主键
        beginTime: null,
        city: null, //所在地区
        concatPhone: null, //联系电话
        district: '', //所在区
        coverImage: '', //封面图
        loginCover: null, // 登录封面
        createId: null,
        createTime: null,
        deadline: null,
        id: null,
        partnerName: null, //伙伴名
        partnerType: null, //合作类型
        partnerWebsite: null,
        province: null,
        updateId: null,
        updateTime: null,
        zcName: null,
        partnerIntro: '', //伙伴简介
        url: '', //机构画面链接
        partnerContactPerson: '', //伙伴对接人
        directLeague: '', //直营或加盟(1->直营;2->加盟)
        cooperationType: ''
      }, //表单收集
      InformationTypes: [], //合作伙伴类型code
      cooperationTypes: [], //合作类型code
      partnerCategory: [], //伙伴类别code
      tabList: [
        {
          id: 1,
          val: '基本资料'
        }
      ],
      tabindex: 1,
      param: {
        keyWord: null
      },
      listgisen: [], //查询所有的合作伙伴数据除城市合伙人外
      dialogVisibles: false,
      idgisen: [], //勾选
      loadingisen: false,
      totaldins: 0, //所有合作伙伴数
      paraminfo: {
        city: '',
        district: '',
        id: 0,
        idList: [],
        orderByParam: '',
        pageNum: 1,
        pageSize: 10,
        province: '',
        queryConditions: ''
      },
      specilist: [],
      operatingLists: [],
      operatingList: [],
      districtList: [],

      rules: {
        district: [{ required: true, message: '请选择地区', trigger: 'change' }],
        directLeague: [{ required: true, message: '请输入合作伙伴类别', trigger: 'blur' }],
        partnerName: [{ required: true, message: '请输入合作名称', trigger: 'blur' }],
        cooperationType:[{ required: true, message: '请输入合作类型', trigger: 'blur' }],
        // partnerWebsite: [{ required: true, message: '请输入合作官网', trigger: 'blur' }],
        partnerContactPerson: [{ required: true, message: '请输入合作对接人', trigger: 'blur' }],
        partnerType: [{ required: true, message: '请勾选合作伙伴类型', trigger: 'blur' }],
        partnerIntro: [{ required: true, message: '请输入合作简介', trigger: 'blur' }],

        city: [{ required: true, message: '请选择地区', trigger: 'change' }],
        concatPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },

          {
            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ],
        coverImage: [{ required: true, message: '请上传封面图', trigger: 'change' }],
        loginCover: [{ required: true, message: '请上传登录封面图', trigger: 'change' }]
      }
    }
  },
  created() {
    this.incity()
    this.selectPartnerById()
    this.getCode('012')
    this.getCode('030')
    this.getCode('029')
  },
  methods: {
    //查询合作类型code
    async getCode(data) {
      const query = {
        codeType: data
      }
      const res = await getCode(query)
      if (res.data.resultCode == 200) {
        if (data == '012') {
          this.InformationTypes = res.data.data
        }
        if (data == '029') {
          this.partnerCategory = res.data.data
        }
        if (data == '030') {
          this.cooperationTypes = res.data.data
        }
      }
    },
    //编辑合作伙伴详情
    async selectPartnerById() {
      const res = await selectPartnerById({ id: this.id })
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        if (this.form.province && this.form.city) {
          this.incity(this.form.province, this.form.city)
        }
        if (this.form.partnerType == '城市合伙人') {
          this.tabList = [
            {
              id: 1,
              val: '基本资料'
            },
            {
              id: 2,
              val: '关联合作伙伴'
            }
          ]
        }
      }
    },

    tabitem(item) {
      this.tabindex = item.id
      if (item.id == 2) {
        this.search()
      }
    },
    //解除关联
    secure(item) {
      const params = {
        associateId: item.id,
        id: this.id
      }

      this.$alert('是否确认解除关联？')
        .then(function () {
          return unbindPartner(params)
        })
        .then(() => {
          this.search()
          this.$message.success('解除成功')
        })
        .catch(() => {})
    },
    //城市
    async incity(province, city) {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
        if (province) {
          this.citychange(province)
        }

        if (city) {
          this.operatingLists.forEach((o) => {
            if (this.form.city == o.name) {
              this.districtList = this.operatingLists.find((el) => el.name == city).cityResults
            }
          })
        }
      }
    },
    //选择省
    citychanges(item) {
      console.log(item)
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfo.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.search()
    },
    //选择区
    districtchanges(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    //选择城市
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          let isnum = item.indexOf('市')
          if (isnum == -1) {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          } else {
            this.form.city = item
            this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            this.operatingLists = []
            this.operatingLists.push(o)
          }
        }
      })
    },
    //选择市
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.form.city == o.name) {
          this.form.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
    },

    async querySearchAsyncapts2(queryString, cb) {
      if (this.form.zcName) {
        const res = await getWisdomGinsengList({
          keyWord: this.form.zcName
        })
        if (res.data.resultCode == 200) {
          this.specilist = res.data.data
          cb(this.specilist)
        }
      } else {
        cb(this.specilist)
      }
    },

    //选择智参中心
    handleSelectcapts2(item) {
      console.log(item)
      this.form.zcName = item.zcName
      this.form.wisdomGinsengId = item.id
    },

    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.sharePictures = val
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.idgisen = selection.map((item) => item.id)
    },
    //城市合伙人关联的合作伙伴一览
    async search() {
      this.queryInfo.id = this.form.id
      this.loading = true
      const res = await cityPartnerPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.loading = false
        if (res.data.data) {
          this.listData = res.data.data.list
        } else {
          this.listData = []
        }

        console.log(this.listData)
        this.total = res.data.data.total
      }
    },
    //批量新增
    clickpartner() {
      this.dialogVisibles = true
      this.getAllPartner()
    },
    //选择城市
    citychang(item) {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfo.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.getAllPartner()
    },
    //选择区
    districtchang(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.getAllPartner()
    },
    //查询所有的合作伙伴数据除城市合伙人外
    async getAllPartner() {
      this.loadingisen = true
      this.paraminfo.id = this.form.id
      const { data: res } = await getAllPartner(this.paraminfo)
      if (res.resultCode == 200) {
        console.log(res)
        this.loadingisen = false
        this.listgisen = res.data.list
        this.totaldins = res.data.total
      }
    },
    //城市合伙人关联企业
    async associateCompany() {
      const query = {
        id: this.form.id,
        ids: this.idgisen
      }
      const { data: res } = await associateCompany(query)
      if (res.resultCode == 200) {
        this.$message.success('关联成功')
        this.dialogVisibles = false
        this.$refs.multipleTables.clearSelection()
        this.search()
      } else {
        this.$message.success('关联失败')
      }
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.sharePictures = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.coverImage = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.coverImage = ''
    },
    removeLoginCover() {
      this.form.loginCover = ''
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      this.form.coverImage = value
    },
    uploadOneLoginCoverShow(value) {
      this.form.loginCover = value
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 分页查询所有的合作伙伴数据除城市合伙人外
    handleSizeChanges(val) {
      this.paraminfo.pageSize = val
      this.getAllPartner()
    },
    // 分页查询所有的合作伙伴数据除城市合伙人外
    handleCurrentChanges(val) {
      this.paraminfo.pageNum = val
      this.getAllPartner()
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.form.id) {
            save(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('新增成功')
                this.$router.go(-1)
              } else {
                this.$message.error(response.data.message)
              }
            })
          } else {
            update(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('编辑成功')
                this.$router.go(-1)
              } else {
                this.$message.error(response.data.message)
              }
            })
          }
        }
      })
    },
    clcans() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/channel/index',
        query: {
          changeNum: true
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}

::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

::v-deep .el-checkbox-group {
  font-size: 0;
  width: 95%;
}
::v-deep .label_code .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
::v-deep .label_code .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #333333;
}
::v-deep .el-radio {
  padding: 5px 0px;
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 14px;
  margin-right: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::v-deep .essential_information .companyName .el-input {
  width: 596px;
}
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .select_inner .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 192px;
}
::v-deep .input-with .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 400px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 590px;
}
::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .cascsder_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.addenterprise {
  width: 100%;
  .tab_txt {
    margin-top: 20px;
    padding: 0px;
    .storeCenter_item {
      width: 100%;
      overflow: hidden;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      position: relative;
      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .Relevancy {
        position: absolute;
        width: 83px;
        height: 25px;
        top: 15px;
        left: 9px;
        background: #ff7d18;
        border-radius: 10px 15px 15px 0px;
        font-size: 12px;
        text-align: center;
        line-height: 25px;
        color: #ffffff;
      }
      .Relevancyblue {
        position: absolute;
        width: 83px;
        height: 25px;
        top: 15px;
        left: 9px;
        background: #146aff;
        border-radius: 10px 15px 15px 0px;
        font-size: 12px;
        text-align: center;
        line-height: 25px;
        color: #ffffff;
      }
      .storeCenter_item_top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        padding-bottom: 14px;
        position: relative;
        background: #ffffff;
        padding: 16px 20px 10px 10px;
        .storeCenter_item_top_type {
          position: absolute;
          width: 93px;
          height: 34px;
          background: #ff7d18;
          color: #fff;
          text-align: center;
          line-height: 34px;
          border-radius: 10px 10px 10px 0px;
          top: 0px;
          left: 0px;
        }
        .storeCenter_item_top_left {
          display: flex;
          flex-direction: row;

          img {
            width: 140px;
            height: 88px;
            border-radius: 2px;
            margin-right: 14px;
          }

          .storeCenter_item_top_left_flex {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .img_commer {
              display: flex;
              border: 1px solid #146aff;
              border-radius: 39px;
              .name {
                font-size: 12px;
                line-height: 20px;
                color: #146aff;
              }
              .color_img {
                background: #146aff;
                height: 20px;
                width: 25px;
                border-radius: 20px;
                position: relative;
                left: -10px;
                img {
                  width: 13px;
                  height: 13px;
                  position: relative;
                  left: 5px;
                  top: 2px;
                }
              }
            }
            .img_commers {
              display: flex;
              border: 1px solid #ff9c00;
              border-radius: 39px;
              margin: 0px 10px;
              .name {
                font-size: 12px;
                line-height: 20px;
                color: #ff9c00;
              }
              .color_img {
                background: #ff9c00;
                height: 20px;
                width: 25px;
                border-radius: 20px;
                position: relative;
                left: -10px;
                img {
                  width: 13px;
                  height: 13px;
                  position: relative;
                  left: 5px;
                  top: 2px;
                }
              }
            }

            h4 {
              color: #1f2d3d;
              font-size: 26px;
              font-weight: 500;
              margin: 0;
            }

            .address {
              font-size: 14px;
              span {
                padding: 0px 10px;
              }
            }
          }
        }
        .storeCenter_item_top_right {
          align-items: stretch;
          height: 100%;
          .account_cycle {
            text-align: right;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

            /* 灰色字体 */

            color: #969495;
          }
          .operation_area {
            margin-top: 30px;
            .buttom_right {
              height: 36px;
              line-height: 1px;
            }
            .edit {
              padding-left: 20px;
              color: #4e93fb;
              cursor: pointer;
            }
            .frozen {
              padding-left: 20px;
              color: #fd5469;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .addenterprise_top {
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    .addenterprise_top_right {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .directLeague {
        display: inline-block;
        width: 32px;
        height: 18px;
        text-align: center;
        line-height: 18px;
        background: #fff2e8;
        color: #ff7d18;
        border-radius: 8px;
        font-size: 12px;
        margin-left: 10px;
      }
    }
    .img_coverImage img {
      width: 149px;
      height: 88px;
      border-radius: 8px;
    }
  }
  .label_code {
    padding: 30px 0px;
  }
  .child_label {
    background: #f5f6f7;
    padding: 10px 15px;
    display: flex;
    align-items: baseline;
    width: 80%;
  }
  .dialog_img {
    padding-bottom: 10px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 30px;
      border-radius: 8px;
    }
    .partnerName {
      margin-left: 10px;
      font-size: 18px;
      color: #212121;
    }
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    margin-top: 20px;
    .form_essential .tips_row {
      font-size: 14px;
      line-height: 40px;
      color: #999;
    }
    .select_inner {
      // padding: 20px;
      background: #ffffff;
    }
    .store_table {
      background: #ffffff;
      height: 70px;
      box-sizing: border-box;
      margin: 0 72px 0px 0;
      padding-left: 10px;
      display: flex;

      .store_table_item {
        margin-right: 50px;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 20px;
      }

      .active {
        color: #4e93fb;
        font-size: 20px;

        border-radius: 1px;
      }
    }
    h4 {
      color: #146aff;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: space-between;
    .input-with-select {
      width: 400px;
      float: right;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .dialog-footer {
    border-top: 1px solid #f5f5f5;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
